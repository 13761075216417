/*
 * @Author: liyu
 * @Date: 2022-03-15 11:25:43
 * @LastEditTime: 2022-05-13 19:36:18
 * @Description: file content
 * @FilePath: /sensors-www/src/components/ScanCodeConsultButton/index.tsx
 */
import * as React from 'react'
import classnames from 'classnames'
import Button, { ButtonProps } from 'components/Base/Button'
import * as styles from './index.module.less'

interface ScanCodeConsultButtonProps extends ButtonProps {
  className?: string
  buttonText?: string
  imgBottomText?: string
  qrcode?: string
  alt?: string
  buttonType?: string
  ghost?: boolean
  style?: React.CSSProperties
  btnTextTwo?: string
  btnTextUrl?: string
}

// 扫码咨询按钮
const ScanCodeConsultButton: React.FC<ScanCodeConsultButtonProps> = ({
  className,
  buttonText,
  qrcode,
  alt,
  buttonType = 'primary',
  ghost,
  style,
  imgBottomText,
  shape = 'default',
  btnTextTwo,
  btnTextUrl
}) => {
  return (
    <div className={classnames(styles.ScanCodeConsultButton, className)} style={style}>
      <Button btnType={buttonType} ghost={ghost} shape={shape} className='showCodeBtn'>
        {buttonText}
      </Button>
      <div className="qrcode-popover">
        <img src={qrcode} alt={alt} />
        {imgBottomText ? <p className="qrcode-img-bottom-text">{imgBottomText}</p> : <p>微信扫码 免费获取</p>}
      </div>
    </div>
  )
}

export default ScanCodeConsultButton
