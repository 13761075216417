// extracted by mini-css-extract-plugin
export var Advantage = "index-module--Advantage--e8Wko";
export var Banner = "index-module--Banner--oo1d7";
export var BannerButtonGroup = "index-module--BannerButtonGroup--E5QUL";
export var BannerIcon = "index-module--BannerIcon--d+F-U";
export var ComputeIcon = "index-module--ComputeIcon--2GID+";
export var CustomerList = "index-module--CustomerList--H5otT";
export var CustomerSwiper = "index-module--CustomerSwiper--XOae2";
export var DutyCardContent = "index-module--DutyCardContent--rkOZ1";
export var DutyCardGroup = "index-module--DutyCardGroup---6hhp";
export var FrameImage = "index-module--FrameImage--XkKzW";
export var FrameworkContent = "index-module--FrameworkContent--I2Xhh";
export var Hidden = "index-module--Hidden--zL22L";
export var Journey = "index-module--Journey--nJ1wo";
export var MatterCardItem = "index-module--MatterCardItem--L5z98";
export var SceneContent = "index-module--SceneContent---AF-s";
export var SectionContentDesc = "index-module--SectionContentDesc--Gx6Py";
export var SwitchPictureWrap = "index-module--SwitchPictureWrap--iLW4a";